<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("occupation.sector")
                }}</label>
                <select
                    v-model="model.sector_id"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('sector_id') }"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(sector, index) in loanSectors"
                        :key="index"
                        :value="sector.id"
                        >{{
                            locale == "kh" ? sector.sector_kh : sector.sector_en
                        }}</option
                    >
                </select>
                <div class="invalid-feedback" v-if="errors.has('sector_id')">
                    {{ errors.first("sector_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("occupation.occupationCode")
                }}</label>
                <input
                    v-model.trim="model.occupation_code"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('occupation_code') }"
                    ref="occupation_code"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('occupation_code')"
                >
                    {{ errors.first("occupation_code") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("occupation.occupationEn")
                }}</label>
                <input
                    v-model.trim="model.occupation_en"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('occupation_en') }"
                    ref="occupation_en"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('occupation_en')"
                >
                    {{ errors.first("occupation_en") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t("occupation.occupationKh")
                }}</label>
                <input
                    v-model.trim="model.occupation_kh"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('occupation_kh') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('occupation_kh')"
                >
                    {{ errors.first("occupation_kh") }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "occupation",
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            loading: false,
            model: {
                sector_id: null,
                occupation_code: null,
                occupation_en: null,
                occupation_kh: null
            }
        };
    },
    computed: {
        ...mapState("creditOperation/occupation", ["edit_data", "loanSectors"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("creditOperation/occupation", ["getLoanSector"]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanSector();
            this.setEditData();
            this.loading = false;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("creditOperation/occupation/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/occupation/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/occupation/update", {
                    id: this.edit_data.id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.occupation_code.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.sector_id = this.edit_data.sector_id;
                this.model.occupation_code = this.edit_data.occupation_code;
                this.model.occupation_en = this.edit_data.occupation_en;
                this.model.occupation_kh = this.edit_data.occupation_kh;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.sector_id = null;
            this.model.occupation_code = null;
            this.model.occupation_en = null;
            this.model.occupation_kh = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "OCCUPATION",
                desc: not.text
            });
        }
    }
};
</script>
